<template>
  <Modal
    data-test-confirm-import
    ref="confirmImportModal"
    :confirmButton="$t('confirmImport.confirm')"
    :hasButtons="true"
    :title="$t('confirmImport.title')"
    width="540px"
    @confirmed="handleConfirm"
    @close="handleClose"
    :style="{ 'z-index': 1000 }"
  >
    <template v-slot:no-tab>
      <div class="text">
        <span class="strong">
          {{ $t('confirmImport.message') }}
        </span>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: 'ConfirmImportModal',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    if (this.show) {
      this.handleModal()
    }
  },

  emits: ['handleConfirm', 'handleClose'],

  watch: {
    show(newValue) {
      if (newValue) {
        this.handleModal()
      }
    },
  },

  methods: {
    handleConfirm() {
      this.handleModal()
      this.$emit('handleConfirm')
    },

    handleClose() {
      this.$emit('handleClose')
    },

    handleModal() {
      this.$refs.confirmImportModal.handleDialog()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
