import api from '@/services/Http'

export const getGroups = async data =>
  (await api()).get('/sync/group/import/verify-spreadsheet', { params: data })

export const getProcessedGroups = async data =>
  (await api()).get('/sync/group/processed-files', { params: data })

export const getProcessedGroupsById = async data =>
  (await api()).get(`/sync/group/processed-files/${data}`)

export const getProcessedGroupsRecords = async data =>
  (await api()).get(
    `/sync/group/processed-files/${data.processedFileId}/records`,
    { params: data.params }
  )

export const confirmImportRecords = async data =>
  (await api()).put(`/sync/group/processed-files/${data}/confirm-import`)

export const rejectImportRecords = async data =>
  (await api()).put(`/sync/group/processed-files/${data}/reject-import`)

export const verifySpreadSheet = async file => {
  const formData = new FormData()

  formData.append('file', file)

  return (await api()).post('/sync/group/import/verify-spreadsheet', formData)
}

export const sendTabsInformations = async tabsInformations => {
  return (await api()).post('/sync/group/import', tabsInformations)
}
