<template>
  <div
    id="performance-page"
    class="page"
    :class="{ 'page-margin': !hideHeaders }"
    data-app
  >
    <div class="page-title" v-show="!hideHeaders" v-if="!importedPageLayout">
      <PageTitle
        :subtitle="$t('PermissionsPage.subtitle')"
        :title="$t('PermissionsPage.title')"
      />
      <div class="buttons">
        <button class="sign-out-button" @click="logout()">
          <v-icon>fi-rr-sign-out</v-icon>
        </button>
        <button
          @click="downloadButtonAction"
          :disabled="isDownloading"
          class="export-button"
        >
          <v-progress-circular
            v-if="isDownloading"
            indeterminate
            :size="15"
            :width="2"
            color="primary"
          />
          <v-icon v-else>fi-rr-download</v-icon>
        </button>
        <button @click="uploadButtonAction" class="import-button">
          <v-icon>fi-rr-cloud-upload</v-icon>
        </button>
      </div>
    </div>
    <PageNavigation
      v-if="!importedPageLayout"
      v-show="!hideHeaders"
      @change="handleNavigation"
      class="page-navigation"
      :routes="routes"
    />
    <router-view />
  </div>
</template>
<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { exportCurrentData } from '@/services/exports'
import { projectAuth } from '@/firebase/config'

const quickRegisterDefaultState = () => ({
  actionSelected: {
    title: '',
    image: '',
    confirmButton: '',
    action: '',
  },
  headerButtons: [],
  footerButtons: [],
  popoverOptions: [],
  show: false,
})

export default {
  name: 'SyncPage',
  data() {
    return {
      isDownloading: false,
      hideHeaders: false,
      quickRegister: quickRegisterDefaultState(),
      pageAddButtonLabel: '',
      pageAddButtonAction: () => {},
      menuOption: null,
    }
  },
  methods: {
    ...mapActions(['setImportedPageLayout']),
    handleNavigation(route) {
      if (!this.$router.currentRoute.path.includes(route)) {
        this.$router.push(route)
      }
    },
    handleHideHeaders(state) {
      this.hideHeaders = state
    },
    uploadButtonAction() {
      switch (this.currentPage) {
        case 'PeoplePage':
          this.$root.$emit('modalPeopleUpload')
          break
        case 'GroupsPage':
          this.$root.$emit('modalGroupUpload')
          break
        case 'OccupationsPage':
          this.$root.$emit('modalOccupationsUpload')
          break
        default:
          break
      }
    },
    async downloadButtonAction() {
      this.isDownloading = true

      try {
        const response = await exportCurrentData()

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url

        const now = moment().format('YYYY-MM-DDTHHmmss')
        link.setAttribute('download', `SyncData-${now}.xlsx`)

        document.body.appendChild(link)

        link.click()
      } catch (e) {
        alert('erro ao exportar pessoas e grupos')
      }

      this.isDownloading = false
    },
    async logout() {
      const $router = this.$router
      projectAuth.signOut().then(() => {
        if (!window.location.href.includes('login')) {
          $router.push('/login')
        }
      })
    },
  },
  computed: {
    ...mapGetters(['importedPageLayout', 'currentPage']),
    routes() {
      const routes = []
      const DataRoutes = [
        {
          path: '/people',
          name: this.$t('Routes.data-people'),
          text: this.$t('Routes.data-people'),
        },
        {
          path: '/groups',
          name: 'Grupos',
          text: 'Grupos',
        },
        {
          path: '/occupations',
          name: this.$t('Routes.data-occupations'),
          text: this.$t('Routes.data-occupations'),
        },
      ]

      routes.push({
        path: '/sync/data',
        name: this.$t('Routes.data'),
        text: this.$t('Routes.data'),
        helpCenter: 'syncPage',
        children: DataRoutes,
      })

      return routes
    },
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
