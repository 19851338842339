<template>
  <div class="form">
    <div class="div-input" data-test-code>
      <CustomInput
        ref="code"
        class="input-title"
        :label="$t('LateralMenu.byCode')"
        @handleInput="handleChangeInput($event, 'code')"
      />
    </div>

    <div class="div-input" data-test-firstName>
      <CustomInput
        ref="firstName"
        class="input-title"
        :label="$t('LateralMenu.byFirstname')"
        @handleInput="handleChangeInput($event, 'firstName')"
      />
    </div>

    <div class="div-input" data-test-lastName>
      <CustomInput
        ref="lastName"
        class="input-title"
        :label="$t('LateralMenu.byLastname')"
        @handleInput="handleChangeInput($event, 'lastName')"
      />
    </div>

    <div class="div-input" data-test-email>
      <CustomInput
        ref="email"
        class="input-title"
        :label="$t('LateralMenu.byEmail')"
        @handleInput="handleChangeInput($event, 'email')"
      />
    </div>

    <div class="div-input" data-test-role>
      <ItemSelector
        ref="role"
        :menuConfig="{
          showTabs: false,
        }"
        :inputConfig="{
          closeOnSelect: true,
          showAvatar: false,
          label: $t('LateralMenu.byRole'),
          placeholder: $t('LateralMenu.byRole'),
        }"
        :menuOptions="_roleOptions"
        @update:item="handleSelector($event, 'role')"
      />
    </div>

    <div class="div-input" data-test-status>
      <ItemSelector
        ref="status"
        :menuConfig="{
          showTabs: false,
        }"
        :inputConfig="{
          closeOnSelect: true,
          showAvatar: false,
          label: $t('LateralMenu.byStatus'),
          placeholder: $t('LateralMenu.byStatus'),
        }"
        :menuOptions="_statusOptions"
        @update:item="handleSelector($event, 'status')"
      />
    </div>

    <div class="div-input" data-test-importStatus>
      <ItemSelector
        ref="importStatus"
        :menuConfig="{
          showTabs: false,
        }"
        :inputConfig="{
          closeOnSelect: true,
          showAvatar: false,
          label: $t('LateralMenu.byImportStatus'),
          placeholder: $t('LateralMenu.byImportStatus'),
        }"
        :menuOptions="_importOptions"
        @update:item="handleSelector($event, 'importStatus')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportedPeople',

  data() {
    return {
      interval: null,
      allowEmit: true,
      form: {
        code: null,
        firstName: null,
        lastName: null,
        email: null,
        role: null,
        status: null,
        importStatus: null,
      },
    }
  },

  computed: {
    _roleOptions() {
      return [
        {
          value: 'role',
          label: this.$t('PeopleService.importedPeoplePageTable.headers.role'),
          type: 'listview',
          items: [
            {
              id: 'admin',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.roles.admin'
              ),
            },
            {
              id: 'manager',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.roles.manager'
              ),
            },
            {
              id: 'user',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.roles.user'
              ),
            },
          ],
        },
      ]
    },

    _statusOptions() {
      return [
        {
          value: 'status',
          label: this.$t(
            'PeopleService.importedPeoplePageTable.headers.status'
          ),
          type: 'listview',
          items: [
            {
              id: 'alert',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.status.alert'
              ),
            },
            {
              id: 'success',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.status.success'
              ),
            },
            {
              id: 'error',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.status.error'
              ),
            },
          ],
        },
      ]
    },

    _importOptions() {
      return [
        {
          value: 'importStatus',
          label: this.$t(
            'PeopleService.importedPeoplePageTable.headers.importStatus'
          ),
          type: 'listview',
          items: [
            {
              id: 'waiting',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.importStatus.waiting'
              ),
            },
            {
              id: 'success',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.importStatus.success'
              ),
            },
            {
              id: 'error',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.importStatus.error'
              ),
            },
            {
              id: 'processing',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.importStatus.processing'
              ),
            },
          ],
        },
      ]
    },
  },

  methods: {
    handleChangeInput(event, key) {
      this.form[key] = event || null
      if (this.allowEmit) this.emitFilter(this.form)
    },

    handleSelector(event, key) {
      this.form[key] = event ? event.id : null
      if (this.allowEmit) this.emitFilter(this.form)
    },

    async clearFilter() {
      this.allowEmit = false

      const event = {
        target: {
          value: '',
        },
      }

      await this.$refs.code.handleInput(event)
      await this.$refs.firstName.handleInput(event)
      await this.$refs.lastName.handleInput(event)
      await this.$refs.email.handleInput(event)
      await this.$refs.role.handleClear()
      await this.$refs.status.handleClear()
      await this.$refs.importStatus.handleClear()

      this.emitFilter({})

      this.allowEmit = true
    },

    emitFilter(payload) {
      console.log(payload)
      this.$root.$emit('change:filter', payload)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
