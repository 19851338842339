var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form"},[_c('div',{staticClass:"div-input",attrs:{"data-test-name":""}},[_c('CustomInput',{ref:"name",staticClass:"input-title",attrs:{"label":_vm.$t('LateralMenu.byName')},on:{"handleInput":function($event){return _vm.handleChangeInput($event, 'name')}}})],1),_c('div',{staticClass:"div-input",attrs:{"data-test-costCenter":""}},[_c('CustomInput',{ref:"costCenter",staticClass:"input-title",attrs:{"label":_vm.$t('LateralMenu.byCostCenter')},on:{"handleInput":function($event){return _vm.handleChangeInput($event, 'costCenter')}}})],1),_c('div',{staticClass:"div-input",attrs:{"data-test-parentGroup":""}},[_c('CustomInput',{ref:"parentGroup",staticClass:"input-title",attrs:{"label":_vm.$t('LateralMenu.byParentGroup')},on:{"handleInput":function($event){return _vm.handleChangeInput($event, 'parentGroup')}}})],1),_c('div',{staticClass:"div-input",attrs:{"data-test-name":""}},[_c('ItemSelector',{ref:"operation",attrs:{"menuConfig":{
        showTabs: false,
      },"inputConfig":{
        closeOnSelect: true,
        showAvatar: false,
        label: _vm.$t('LateralMenu.byOperation'),
        placeholder: _vm.$t('LateralMenu.byOperation'),
      },"menuOptions":_vm._operationOptions},on:{"update:item":function($event){return _vm.handleSelector($event, 'operation')}}})],1),_c('div',{staticClass:"div-input",attrs:{"data-test-status":""}},[_c('ItemSelector',{ref:"status",attrs:{"menuConfig":{
        showTabs: false,
      },"inputConfig":{
        closeOnSelect: true,
        showAvatar: false,
        label: _vm.$t('LateralMenu.byStatus'),
        placeholder: _vm.$t('LateralMenu.byStatus'),
      },"menuOptions":_vm._statusOptions},on:{"update:item":function($event){return _vm.handleSelector($event, 'status')}}})],1),_c('div',{staticClass:"div-input",attrs:{"data-test-importStatus":""}},[_c('ItemSelector',{ref:"importStatus",attrs:{"menuConfig":{
        showTabs: false,
      },"inputConfig":{
        closeOnSelect: true,
        showAvatar: false,
        label: _vm.$t('LateralMenu.byImportStatus'),
        placeholder: _vm.$t('LateralMenu.byImportStatus'),
      },"menuOptions":_vm._importOptions},on:{"update:item":function($event){return _vm.handleSelector($event, 'importStatus')}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }