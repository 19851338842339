import Vue from 'vue'
import Vuex from 'vuex'
import currentUser from './modules/currentUser'
import currentAccount from './modules/currentAccount'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    workflowColors: {},
    currentPage: '',
    alert: {
      model: false,
      statusAlert: '',
      messageAlert: '',
    },
    reload: null,
    importedPageLayout: false,
  },
  getters: {
    workflowColors: state => state.workflowColors,
    currentPage: state => state.currentPage,
    importedPageLayout: state => state.importedPageLayout,
  },
  mutations: {
    setWorkflowColors(state, value) {
      state.workflowColors = value
    },
    setCurrentPage(state, page) {
      state.currentPage = page
    },
    setImportedPageLayout(state, value) {
      state.importedPageLayout = value
    },
  },
  actions: {
    setWorkflowColors({ commit }, value) {
      commit('setWorkflowColors', value)
    },
    setCurrentPage({ commit }, page) {
      commit('setCurrentPage', page)
    },
    setImportedPageLayout({ commit }, value) {
      commit('setImportedPageLayout', value)
    },
  },
  modules: {
    currentUser,
    currentAccount,
  },
})
