<template>
  <div data-app class="overflow-hidden">
    <div class="card">
      <div class="header">
        <i class="fi-sr-arrow-left" @click="handleBack()"></i>

        <div class="row">
          <div class="col-6">
            <div class="header-title">
              <h1>{{ $t('GroupsService.importedPage.header_title') }}</h1>
              <HowToBeginAndWatchAVideo videoType="syncPage" short />
            </div>

            <div class="header-subtitle">
              <h3>{{ $t('GroupsService.importedPage.header_subTitle') }}</h3>
            </div>
          </div>

          <div class="col-6" v-if="importedData" data-test-imported-data>
            <div class="csv-details" data-test-file-name>
              <span>
                <strong>
                  {{
                    $t(
                      'GroupsService.importedGroupsPageTable.importedData.name'
                    )
                  }}:
                </strong>
                {{ importedData.filename }}
              </span>

              <span data-test-status>
                <strong>
                  {{
                    $t(
                      'GroupsService.importedGroupsPageTable.importedData.status'
                    )
                  }}:
                </strong>
                {{ $t(`GroupTab.status.${importedData.status}`) }}
              </span>
              <span data-test-id>
                <strong>
                  {{
                    $t('GroupsService.importedGroupsPageTable.importedData.id')
                  }}:
                </strong>
                {{ importedData.id }}
              </span>
            </div>
            <div class="csv-details">
              <span data-test-group-amount>
                <strong>
                  {{
                    $t(
                      'GroupsService.importedGroupsPageTable.importedData.total'
                    )
                  }}:
                </strong>
                {{ importedData.groupAmount }}
              </span>
              <span data-test-amount-success>
                <strong>
                  {{
                    $t(
                      'GroupsService.importedGroupsPageTable.importedData.success'
                    )
                  }}:
                </strong>
                {{ importedData.amountSuccess }}
              </span>
              <span data-test-amount-error>
                <strong>
                  <strong>
                    {{
                      $t(
                        'GroupsService.importedGroupsPageTable.importedData.fail'
                      )
                    }}:
                  </strong>
                </strong>
                {{ importedData.amountError }}
              </span>
              <span data-test-amount-alert>
                <strong>
                  {{
                    $t(
                      'GroupsService.importedGroupsPageTable.importedData.alert'
                    )
                  }}:
                </strong>
                {{ importedData.amountAlert }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="importedData && importedData.status === 'waiting'" class="card">
      <v-spacer />
      <div class="action-buttons">
        <v-btn
          class="btn-cancel"
          :disabled="loading"
          data-test-reject-import
          small
          @click="rejectImportRecords()"
        >
          {{ $t('rejectImport.title') }}
        </v-btn>
        <v-btn
          class="btn-confirm"
          :disabled="loading"
          data-test-confirm-import
          small
          @click="handleConfirmImportRecords()"
        >
          {{ $t('confirmImport.title') }}
        </v-btn>
      </div>
    </div>

    <div class="card">
      <div id="imported-people-page">
        <div class="table-header">
          <span class="table-header-title">
            {{ $t('GroupTab.tableHeaderTitle') }}
          </span>
        </div>

        <div v-if="loading">
          <v-skeleton-loader type="table-tbody" data-test-ranking-loading />
        </div>

        <div v-else class="table-holder">
          <DataTable
            id="DataTable"
            :title="$t('GroupTab.tableHeaderTitle')"
            :headers="_headers"
            :hideHeader="true"
            :hideOption="true"
            :contentStyle="false"
            :enablePagination="false"
            :hideSearch="true"
            :items="groups"
            :itemsPerPage="pagination.limit"
            :skeletonLines="pagination.limit"
          />

          <div class="pagination">
            <v-btn
              data-test-table-pagination-previous
              v-if="pagination.page > 1"
              x-small
              tile
              icon
              @click="handlePagination(pagination.page - 1)"
            >
              <v-icon size="10">fi fi-br-angle-left</v-icon>
            </v-btn>

            <div class="current">
              <span class="label-pagination"> {{ pagination.page }} </span>
            </div>

            <v-btn
              data-test-table-pagination-next
              v-if="pagination.page < pagination.total"
              x-small
              tile
              icon
              @click="handlePagination(pagination.page + 1)"
            >
              <v-icon size="10">fi fi-br-angle-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <ConfirmImportModal
      :show="importDialog"
      @handleConfirm="confirmImportRecords()"
      @handleClose="importDialog = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  getProcessedGroupsById,
  getProcessedGroupsRecords,
  confirmImportRecords,
  rejectImportRecords,
} from '@/services/groups'
import ConfirmImportModal from '../../Partials/ConfirmImportModal/ConfirmImportModal.vue'
import { GROUPS_ACTIONS } from '@/helpers/upload-actions'

export default {
  name: 'ImportedGroupPage',

  data() {
    return {
      loading: true,
      importedData: null,
      importDialog: false,
      pagination: {
        page: 1,
        limit: 20,
        offset: 0,
        total: 0,
      },
      filters: {},
      groups: [],
    }
  },

  components: {
    ConfirmImportModal,
  },

  beforeMount() {
    this.$root.$on('change:filter', this.handleFilter)
  },

  mounted() {
    this.setImportedPageLayout(true)

    this.getProcessedGroupsById()
    this.getProcessedGroupsRecords()
  },

  beforeDestroy() {
    this.$root.$off('change:filter', this.handleFilter)
  },

  computed: {
    ...mapGetters(['importedPageLayout']),

    _statusOptions() {
      return [
        {
          value: 'status',
          label: 'Status',
          type: 'listview',
          items: [
            {
              id: 'alert',
              label: this.$t(
                'GroupsService.importedGroupsPageTable.status.alert'
              ),
            },
            {
              id: 'success',
              label: this.$t(
                'GroupsService.importedGroupsPageTable.status.success'
              ),
            },
            {
              id: 'error',
              label: this.$t(
                'GroupsService.importedGroupsPageTable.status.error'
              ),
            },
          ],
        },
      ]
    },

    _headers() {
      const isUpdateInformation =
        this.importedData?.action === GROUPS_ACTIONS.InformationUpdate

      const headers = isUpdateInformation
        ? [
            {
              text: 'ID',
              value: 'groupID',
              type: 'text',
            },
            {
              text: this.$t(
                'GroupsService.importedGroupsPageTable.headers.costCenter'
              ),
              value: 'costCenter',
              type: 'text',
            },
            {
              text: this.$t(
                'GroupsService.importedGroupsPageTable.headers.name'
              ),
              value: 'name',
              type: 'text',
            },
          ]
        : [
            {
              text: this.$t(
                'GroupsService.importedGroupsPageTable.headers.costCenter'
              ),
              value: 'costCenter',
              type: 'text',
            },
            {
              text: this.$t(
                'GroupsService.importedGroupsPageTable.headers.name'
              ),
              value: 'name',
              type: 'text',
            },
            {
              text: this.$t(
                'GroupsService.importedGroupsPageTable.headers.parentGroup'
              ),
              value: 'parentGroup',
              type: 'text',
            },
            {
              text: this.$t(
                'GroupsService.importedGroupsPageTable.headers.managers'
              ),
              value: 'managers',
              type: 'message-popup',
            },
            {
              text: this.$t(
                'GroupsService.importedGroupsPageTable.headers.members'
              ),
              value: 'members',
              type: 'message-popup',
            },
          ]

      headers.push(
        ...[
          {
            text: this.$t(
              'GroupsService.importedGroupsPageTable.headers.operation'
            ),
            value: 'operation',
            type: 'text',
          },
          {
            text: this.$t(
              'GroupsService.importedGroupsPageTable.headers.status'
            ),
            value: 'status',
            type: 'text-status',
          },
          {
            text: this.$t(
              'GroupsService.importedGroupsPageTable.headers.message'
            ),
            value: 'message',
            type: 'text',
          },
          {
            text: this.$t(
              'GroupsService.importedGroupsPageTable.headers.importStatus'
            ),
            value: 'importStatus',
            type: 'text-status',
          },
          {
            text: this.$t(
              'GroupsService.importedGroupsPageTable.headers.importStatusMessage'
            ),
            value: 'importStatusMessage',
            type: 'text',
          },
        ]
      )

      return headers
    },
  },

  methods: {
    ...mapActions(['setImportedPageLayout']),

    async getProcessedGroupsById() {
      if (!this.$route.params.id) return

      await getProcessedGroupsById(this.$route.params.id).then(({ data }) => {
        this.importedData = data
      })
    },

    async getProcessedGroupsRecords() {
      if (!this.$route.params.id) return

      this.loading = true

      const payload = {
        processedFileId: this.$route.params.id,
        params: {
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          ...this.filters,
        },
      }

      await getProcessedGroupsRecords(payload)
        .then(({ data, headers }) => {
          this.groups = data.map(el => {
            return {
              ...el,
              costCenter: el.costCenter ? el.costCenter : '--',
              parentGroup: el.parentGroup ? el.parentGroup : '--',
              operation: this.$t(
                `GroupsService.importedGroupsPageTable.operation.${el.operation}`
              ),
              managers: {
                content: `${el.managers ? el.managers.length : 0} ${
                  el.managers && el.managers.length === 1
                    ? this.$t(
                        `GroupsService.importedGroupsPageTable.managers.singular`
                      )
                    : this.$t(
                        `GroupsService.importedGroupsPageTable.managers.plural`
                      )
                }`,
                type: 'list',
                label: this.$t(
                  `GroupsService.importedGroupsPageTable.managers.plural`
                ),
                list:
                  !!el.managers &&
                  Array.isArray(el.managers) &&
                  el.managers.length
                    ? el.managers.map(el => ({
                        title: el.name,
                        subtitle: `${this.$t(
                          `GroupsService.importedGroupsPageTable.code`
                        )}: ${el.remoteId}`,
                      }))
                    : [],
                width: '20rem',
              },
              members: {
                content: `${el.members ? el.members.length : 0} ${
                  el.members && el.members.length === 1
                    ? this.$t(
                        `GroupsService.importedGroupsPageTable.members.singular`
                      )
                    : this.$t(
                        `GroupsService.importedGroupsPageTable.members.plural`
                      )
                }`,
                type: 'list',
                label: this.$t(
                  `GroupsService.importedGroupsPageTable.members.plural`
                ),
                list:
                  !!el.members && Array.isArray(el.members) && el.members.length
                    ? el.members.map(el => ({
                        title: el.name,
                        subtitle: `${this.$t(
                          `GroupsService.importedGroupsPageTable.code`
                        )}: ${el.remoteId}`,
                      }))
                    : [],
                width: '20rem',
              },
              status: {
                status: this.formatStatus(el.status),
                label: this.$t(
                  `GroupsService.importedGroupsPageTable.status.${el.status}`
                ),
              },
              importStatus: {
                status:
                  (el.operation === 'insert' ||
                    el.operation?.includes('update')) &&
                  el.status !== 'error'
                    ? this.formatImpotedStatus(el.importStatus)
                    : '',
                label:
                  (el.operation === 'insert' ||
                    el.operation?.includes('update')) &&
                  el.status !== 'error'
                    ? this.$t(
                        `GroupsService.importedGroupsPageTable.importStatus.${el.importStatus}`
                      )
                    : '--',
              },
              message: el.message ? el.message : '--',
            }
          })

          let total = headers['x-count'] / this.pagination.limit
          if (total % 1 !== 0) total = total + 1

          this.pagination.total = Math.trunc(total)
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleConfirmImportRecords() {
      this.importDialog = true
    },

    async confirmImportRecords() {
      if (!this.$route.params.id) return

      this.loading = true

      await confirmImportRecords(this.$route.params.id)
        .then(() => {
          this.handleBack()
        })
        .catch(() => {
          const message = {
            type: 'error',
            message: this.$t('confirmImport.error'),
          }

          this.$emit('alert', message)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async rejectImportRecords() {
      if (!this.$route.params.id) return

      this.loading = true

      await rejectImportRecords(this.$route.params.id)
        .then(() => {
          this.handleBack()
        })
        .catch(() => {
          const message = {
            type: 'error',
            message: this.$t('rejectImport.error'),
          }

          this.$emit('alert', message)
        })
        .finally(() => {
          this.loading = false
        })
    },

    handlePagination(value) {
      this.pagination.page = value
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit

      this.getProcessedGroupsRecords()
    },

    handleStatus(event) {
      const payload = {
        status: event ? event.id : null,
      }

      this.filters = {
        ...this.filters,
        ...payload,
      }

      this.pagination.page = 1

      this.getProcessedGroupsRecords()
    },

    formatStatus(value) {
      const data = {
        alert: 'waiting',
        success: 'success',
        error: 'error',
      }

      return data[value]
    },

    formatImpotedStatus(value) {
      const data = {
        waiting: 'waiting',
        success: 'success',
        error: 'error',
        processing: 'waiting',
      }

      return data[value]
    },

    handleFilter(event) {
      this.filters = {
        ...event,
      }

      this.pagination.page = 1

      this.getProcessedGroupsRecords()
    },

    handleBack() {
      this.setImportedPageLayout(false)
      this.$router.push({ name: 'GroupsPage' })
    },
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
