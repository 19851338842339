import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueApexCharts from 'vue-apexcharts'
import { VueMaskDirective } from 'v-mask'
import '@mdi/font/css/materialdesignicons.css'
import 'apexcharts/dist/apexcharts.css'
import TcComponents from '@teamculture/components'
import '@fontsource/poppins'
import '@/styles/styles.scss'

import { abilitiesPlugin, Can } from '@casl/vue'
import { ability, abilityHelper } from './helpers/ability'
import { projectAuth } from '@/firebase/config'

Vue.config.productionTip = false

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('apexchart', VueApexCharts)
Vue.use(TcComponents)

Vue.directive('mask', VueMaskDirective)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Can', Can)
Vue.use(abilitiesPlugin, ability)

let app = ''
// initialize app after firebase setup
projectAuth.onAuthStateChanged(async () => {
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  }
})

if (window.Cypress) {
  // be visible only during e2e tests
  window.app = app
  window.abilityHelper = abilityHelper
}
