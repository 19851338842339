import api from '@/services/Http'

export const getOccupations = async data =>
  (await api()).get('/sync/occupations/import/verify-spreadsheet', {
    params: data,
  })

export const getProcessedOccupations = async data =>
  (await api()).get('/sync/occupations/processed-files', { params: data })

export const getProcessedOccupationsById = async data =>
  (await api()).get(`/sync/occupations/processed-files/${data}`)

export const getProcessedOccupationsRecords = async data =>
  (await api()).get(
    `/sync/occupations/processed-files/${data.processedFileId}/records`,
    { params: data.params }
  )

export const confirmImportRecords = async data =>
  (await api()).put(`/sync/occupations/processed-files/${data}/confirm-import`)

export const rejectImportRecords = async data =>
  (await api()).put(`/sync/occupations/processed-files/${data}/reject-import`)

export const verifySpreadSheet = async file => {
  const formData = new FormData()

  formData.append('file', file)

  return (await api()).post(
    '/sync/occupations/import/verify-spreadsheet',
    formData
  )
}

export const sendTabsInformations = async tabsInformations => {
  return (await api()).post('/sync/occupations/import', tabsInformations)
}
