<template>
  <LateralMenu>
    <template v-slot="{ isMini }">
      <LateralMenuItem
        :title="$t('LateralMenu.filterTitle')"
        :isMini="isMini"
        class="lateral-menu-item"
      >
        <template v-slot:title-icon="{ size }">
          <v-icon :size="size">mdi-flash</v-icon>
        </template>

        <template v-slot:header-action>
          <v-btn
            class="pa-1"
            plain
            text
            small
            @click="clearFilter()"
            data-test-clean-entity-filter
            >{{ $t('LateralMenu.actions.clean') }}</v-btn
          >
        </template>

        <template>
          <div>
            <ImportedPeople
              v-if="_currentPage === 'ImportedPeoplePage'"
              ref="importedPeople"
            />

            <ImportedGroups
              v-if="_currentPage === 'ImportedGroupsPage'"
              ref="importedGroups"
            />
          </div>
        </template>
      </LateralMenuItem>
    </template>
  </LateralMenu>
</template>
<script>
import ImportedPeople from './Partials/ImportedPeople/ImportedPeople'
import ImportedGroups from './Partials/ImportedGroups/ImportedGroups'

export default {
  name: 'LateralMenuView',

  props: {
    isMini: Boolean,
  },

  components: {
    ImportedPeople,
    ImportedGroups,
  },

  data() {
    return {}
  },

  computed: {
    _currentPage() {
      return this.$route.name
    },
  },

  methods: {
    clearFilter() {
      if (this._currentPage === 'ImportedPeoplePage')
        this.$refs.importedPeople.clearFilter()

      if (this._currentPage === 'ImportedGroupsPage')
        this.$refs.importedGroups.clearFilter()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
