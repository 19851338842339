<template>
  <v-app id="app">
    <v-main class="page">
      <v-container fluid class="temp-container">
        <router-view />
      </v-container>
    </v-main>

    <LateralMenu v-if="_showFilter" />
  </v-app>
</template>
<script>
import LateralMenu from './views/LateralMenu/LateralMenu.vue'
import { projectAuth } from '@/firebase/config'

export default {
  name: 'SyncApp',

  components: {
    LateralMenu,
  },

  computed: {
    _showFilter() {
      const routes = ['ImportedPeoplePage', 'ImportedGroupsPage']

      return routes.includes(this.$route.name)
    },
  },

  data: () => ({
    isSignedin: false,
  }),

  created() {
    this.isSignedin = false
    const $router = this.$router
    projectAuth.onAuthStateChanged(async user => {
      if (user) {
        this.isSignedin = true
        if (window.location.href.includes('login')) {
          $router.push('/')
        }
      } else {
        this.isSignedin = false
      }
    })
  },
}
</script>

<style lang="scss">
#app {
  display: flex;
}
.temp-container {
  height: 100%;
  padding: inherit !important;
}
</style>
