export const management = 'management'

export const management_groups = 'management.groups'
export const management_groups_create = 'management.groups.creator'
export const management_groups_edit = 'management.groups.edit'
export const management_groups_edit_name = 'management.groups.edit.name'
export const management_groups_edit_costCenter =
  'management.groups.edit.costCenter'
export const management_groups_edit_people = 'management.groups.edit.people'
export const management_groups_edit_subgroups =
  'management.groups.edit.subgroups'
export const management_groups_delete = 'management.groups.delete'
export const management_groups_move = 'management.groups.move'
export const management_groups_duplicate = 'management.groups.duplicate'
export const management_groups_activate = 'management.groups.activate'
export const management_groups_inactivate = 'management.groups.inactivate'

export const management_permissions = 'management.permissions'
export const management_permissions_create = 'management.permissions.creator'
export const management_permissions_edit = 'management.permissions.edit'
export const management_permissions_delete = 'management.permissions.delete'

export const management_users = 'management.users'
export const management_users_create = 'management.users.creator'
export const management_users_delete = 'management.users.delete'
export const management_users_edit = 'management.users.edit'
export const management_users_edit_detail = 'management.users.edit.detail'
export const management_users_edit_groups = 'management.users.edit.groups'
export const management_users_edit_attributes =
  'management.users.edit.attributes'
export const management_users_activate = 'management.users.activate'
export const management_users_inactivate = 'management.users.inactivate'
export const management_users_resent = 'management.users.resent'

export const management_performance_entities = 'management.performance.entities'
export const management_performance = 'management.performance'

export const management_performance_group_roles =
  'management.performance.group-roles'
export const management_performance_group_roles_create =
  'management.performance.group-roles.create'
export const management_performance_group_roles_edit =
  'management.performance.group-roles.edit'
export const management_performance_group_roles_delete =
  'management.performance.group-roles.delete'

export const management_performance_entities_access_profiles =
  'management.performance.entities.access-profiles'
export const management_performance_entities_access_profiles_create =
  'management.performance.entities.access-profiles.create'
export const management_performance_entities_access_profiles_edit =
  'management.performance.entities.access-profiles.edit'
export const management_performance_entities_access_profiles_delete =
  'management.performance.entities.access-profiles.delete'
