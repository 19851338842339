<template>
  <Modal
    ref="modalGroupUpload"
    :title="$t('GroupsService.Modal.ModalTitle')"
    width="60%"
    :zIndex="999"
    :has-tab="false"
  >
    <template v-slot:[`no-tab`]>
      <div class="modal-upload">
        <div class="modal-upload-info" v-show="!showForm">
          <p>
            {{ $t('GroupsService.Modal.headerInstruction') }}
          </p>
          <a href="#">{{ $t('GroupsService.Modal.downloadSheets') }}</a>
        </div>

        <AddFile @file="validateFile" :label="fileName" />

        <div v-if="showForm">
          <span class="modal-upload-subtitle">
            {{ $t('GroupsService.Modal.modalsSubtitle') }}
          </span>
          <v-divider></v-divider>
        </div>

        <div v-if="loading" class="loading">
          <v-progress-circular indeterminate color="primary" />
        </div>

        <v-container fluid v-if="showForm">
          <v-radio-group v-model="action" inline class="modal-upload-action">
            <v-radio
              :label="
                $t('GroupsService.Modal.labels.action.FullHierarchyAddUpdate')
              "
              :value="GROUPS_ACTIONS.FullHierarchyAddUpdate"
            ></v-radio>
            <v-radio
              :label="$t('GroupsService.Modal.labels.action.PartialAddUpdate')"
              :value="GROUPS_ACTIONS.PartialAddUpdate"
            ></v-radio>
            <v-radio
              :label="$t('GroupsService.Modal.labels.action.InformationUpdate')"
              :value="GROUPS_ACTIONS.InformationUpdate"
            ></v-radio>
          </v-radio-group>
        </v-container>

        <v-form
          ref="form"
          @submit.prevent
          v-show="showForm"
          class="modal-upload-form"
          v-model="valid"
        >
          <v-container>
            <div class="single-input">
              <SingleSelection
                :selectedItem="selectedTab"
                :label="$t('GroupsService.Modal.labels.groupLabel')"
                :placeholder="$t('GroupsService.Modal.labels.groupLabel')"
                :items="tabs"
                @selectOption="handleTabSelection"
              />
            </div>

            <div
              v-if="selectedTab && action !== GROUPS_ACTIONS.InformationUpdate"
              class="inline-inputs"
            >
              <div class="input-holder">
                <SingleSelection
                  :label="$t('GroupsService.Modal.labels.costCenter')"
                  :placeholder="$t('GroupsService.Modal.labels.costCenter')"
                  :items="groupColumns"
                  :rules="[rules.required]"
                  @selectOption="handleSelectOption($event, 'costCenter')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('GroupsService.Modal.labels.name')"
                  :placeholder="$t('GroupsService.Modal.labels.name')"
                  :items="groupColumns"
                  :rules="[rules.required]"
                  @selectOption="handleSelectOption($event, 'name')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('GroupsService.Modal.labels.parentGroup')"
                  :placeholder="$t('GroupsService.Modal.labels.parentGroup')"
                  :items="groupColumns"
                  @selectOption="handleSelectOption($event, 'parentGroup')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('GroupsService.Modal.labels.managers')"
                  :placeholder="$t('GroupsService.Modal.labels.managers')"
                  :items="groupColumns"
                  @selectOption="handleSelectOption($event, 'managers')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('GroupsService.Modal.labels.members')"
                  :placeholder="$t('GroupsService.Modal.labels.members')"
                  :items="groupColumns"
                  @selectOption="handleSelectOption($event, 'members')"
                />
              </div>
            </div>
            <div
              v-if="selectedTab && action === GROUPS_ACTIONS.InformationUpdate"
              class="inline-inputs"
            >
              <div class="input-holder">
                <SingleSelection
                  :label="$t('GroupsService.Modal.labels.oid')"
                  :placeholder="$t('GroupsService.Modal.labels.oid')"
                  :items="groupColumns"
                  :rules="[rules.required]"
                  @selectOption="handleSelectOption($event, 'groupID')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('GroupsService.Modal.labels.costCenter')"
                  :placeholder="$t('GroupsService.Modal.labels.costCenter')"
                  :items="groupColumns"
                  :rules="[rules.required]"
                  @selectOption="handleSelectOption($event, 'costCenter')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('GroupsService.Modal.labels.name')"
                  :placeholder="$t('GroupsService.Modal.labels.name')"
                  :items="groupColumns"
                  :rules="[rules.required]"
                  @selectOption="handleSelectOption($event, 'name')"
                />
              </div>
            </div>
          </v-container>
        </v-form>
        <v-divider v-show="showForm && selectedTab"></v-divider>
        <div class="dialog-footer" v-show="showForm && selectedTab">
          <v-btn
            @click="handleDialog()"
            class="dialog-footer-btnCancel"
            depressed
            plain
          >
            {{ $t('GroupsService.modalButtons.cancel') }}
          </v-btn>
          <v-btn
            @click="SendTabsInformations"
            class="dialog-footer-btn"
            depressed
          >
            {{ $t('GroupsService.modalButtons.loadGroup') }}
          </v-btn>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import { GROUPS_ACTIONS } from '@/helpers/upload-actions'
import AddFile from '@/views/SyncPage/Tabs/DataPage/Tabs/PeoplePage/Partials/UploadModal/Partials/AddFile/AddFile.vue'

import {
  verifySpreadSheet,
  sendTabsInformations,
} from '@/services/groups/index'

export default {
  components: {
    AddFile,
  },
  data() {
    return {
      GROUPS_ACTIONS,
      action: GROUPS_ACTIONS.FullHierarchyAddUpdate,
      showForm: false,
      fileName: '',
      loading: false,
      columns: {},
      valid: false,
      tabsInformations: {
        fileLocationId: '',
        tab: {
          name: '',
          columns: [],
        },
      },
      selectedTab: '',
      rules: {
        required: v => {
          const requiredLabel = this.$t('GroupsService.Modal.requiredField')

          if (v instanceof Array && v.length == 0) return requiredLabel
          return !!v || requiredLabel
        },
      },
    }
  },
  watch: {
    selectedTab() {
      this.reset()
    },
  },
  computed: {
    tabs() {
      return this.columns?.tabs?.map(el => el.name)
    },
    groupColumns() {
      return this.columns?.tabs?.find(el => el.name === this.selectedTab)
        .columns
    },
  },
  methods: {
    initiliazeData() {
      this.action = GROUPS_ACTIONS.FullHierarchyAddUpdate
      this.showForm = false
      this.fileName = ''
      this.columns = {}
      this.selectedTab = ''
      this.tabsInformations = {
        fileLocationId: '',
        tab: {
          name: '',
          columns: [],
        },
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    handleDialog() {
      this.initiliazeData()
      this.$refs.modalGroupUpload.handleDialog()
    },
    handleTabSelection({ value }) {
      this.selectedTab = value
      this.tabsInformations.tab.name = value
    },
    handleSelectOption({ value }, name) {
      const columnWithAtributte = {
        column: value,
        attribute: name,
      }

      this.tabsInformations.tab.columns.push(columnWithAtributte)
    },
    async validateFile(file) {
      this.loading = true
      if (file) {
        this.fileName = file.name
        await verifySpreadSheet(file)
          .then(response => {
            this.loading = false
            this.showForm = true
            this.columns = response.data
          })
          .catch(error => {
            this.loading = false
            this.fileName = ''
            this.showForm = false
            this.$emit('alert', {
              type: 'error',
              message: error?.response?.data?.message || this.$t('uploadError'),
            })
          })
      }
    },
    async SendTabsInformations() {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }

      this.tabsInformations.action = this.action
      this.tabsInformations.fileLocationId = this.columns.fileLocationId

      this.loading = true
      this.showForm = false

      await sendTabsInformations(this.tabsInformations)
        .then(response => {
          this.$emit('alert', {
            type: 'success',
            message: response.data.message,
          })
          this.loading = false
          this.$emit('updateGroupsList')
          this.handleDialog()
        })
        .catch(error => {
          this.loading = false
          this.showForm = true
          this.$emit('alert', {
            type: 'error',
            message: error.response.data.message,
          })
        })
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped></style>
