import axios from 'axios'
import validateUnauthorized from '@/helpers/axios/on-response-validate-unauthorized'
import { projectAuth } from '@/firebase/config'

const baseURL = process.env.VUE_APP_API_BASE_URL

const Api = async () => {
  const token = await projectAuth.currentUser.getIdToken()
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const http = axios.create({ baseURL, headers })

  http.interceptors.response.use(
    response => {
      return response
    },
    async function (error) {
      return Promise.reject(Object.assign(error, validateUnauthorized(error)))
    }
  )
  return http
}

export default Api
