<template>
  <div data-app class="overflow-hidden">
    <div class="card">
      <div class="header">
        <i class="fi-sr-arrow-left" @click="handleBack()"></i>

        <div class="row">
          <div class="col-6">
            <div class="header-title">
              <h1>{{ $t('PeopleService.importedPage.header_title') }}</h1>
              <HowToBeginAndWatchAVideo videoType="syncPage" short />
            </div>

            <div class="header-subtitle">
              <h3>{{ $t('PeopleService.importedPage.header_subTitle') }}</h3>
            </div>
          </div>

          <div class="col-6" v-if="importedData" data-test-imported-data>
            <div class="csv-details" data-test-file-name>
              <span>
                <strong>
                  {{
                    $t(
                      'PeopleService.importedPeoplePageTable.importedData.name'
                    )
                  }}:
                </strong>
                {{ importedData.filename }}
              </span>
              <span data-test-status>
                <strong>
                  {{
                    $t(
                      'PeopleService.importedPeoplePageTable.importedData.status'
                    )
                  }}:
                </strong>
                {{ $t(`PeopleTab.status.${importedData.status}`) }}
              </span>
              <span data-test-id>
                <strong>
                  {{
                    $t('PeopleService.importedPeoplePageTable.importedData.id')
                  }}:
                </strong>
                {{ importedData.id }}
              </span>
            </div>
            <div class="csv-details">
              <span data-test-people-amount>
                <strong>
                  {{
                    $t(
                      'PeopleService.importedPeoplePageTable.importedData.total'
                    )
                  }}:
                </strong>
                {{ importedData.peopleAmount }}
              </span>
              <span data-test-amount-success>
                <strong>
                  {{
                    $t(
                      'PeopleService.importedPeoplePageTable.importedData.success'
                    )
                  }}:
                </strong>
                {{ importedData.amountSuccess }}
              </span>
              <span data-test-amount-error>
                <strong>
                  {{
                    $t(
                      'PeopleService.importedPeoplePageTable.importedData.fail'
                    )
                  }}:
                </strong>
                {{ importedData.amountError }}
              </span>
              <span data-test-amount-alert>
                <strong>
                  {{
                    $t(
                      'PeopleService.importedPeoplePageTable.importedData.alert'
                    )
                  }}:
                </strong>
                {{ importedData.amountAlert }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="importedData && importedData.status === 'waiting'" class="card">
      <v-spacer />
      <div class="action-buttons">
        <v-btn
          class="btn-cancel"
          :disabled="loading"
          data-test-reject-import
          small
          @click="rejectImportRecords()"
        >
          {{ $t('rejectImport.title') }}
        </v-btn>
        <v-btn
          class="btn-confirm"
          :disabled="loading"
          data-test-confirm-import
          small
          @click="handleConfirmImportRecords()"
        >
          {{ $t('confirmImport.title') }}
        </v-btn>
      </div>
    </div>

    <div class="card">
      <div id="imported-people-page">
        <div class="table-header">
          <span class="table-header-title">
            {{ $t('PeopleTab.tableHeaderTitle') }}
          </span>
        </div>

        <div v-if="loading">
          <v-skeleton-loader type="table-tbody" data-test-ranking-loading />
        </div>

        <div v-else class="table-holder">
          <DataTable
            id="DataTable"
            :title="$t('PeopleTab.tableHeaderTitle')"
            :headers="_headers"
            :hideHeader="true"
            :hideOption="true"
            :contentStyle="false"
            :enablePagination="false"
            :hideSearch="true"
            :items="people"
            :itemsPerPage="pagination.limit"
            :skeletonLines="pagination.limit"
          />

          <div class="pagination">
            <v-btn
              data-test-table-pagination-previous
              v-if="pagination.page > 1"
              x-small
              tile
              icon
              @click="handlePagination(pagination.page - 1)"
            >
              <v-icon size="10">fi fi-br-angle-left</v-icon>
            </v-btn>

            <div class="current">
              <span class="label-pagination"> {{ pagination.page }} </span>
            </div>

            <v-btn
              data-test-table-pagination-next
              v-if="pagination.page < pagination.total"
              x-small
              tile
              icon
              @click="handlePagination(pagination.page + 1)"
            >
              <v-icon size="10">fi fi-br-angle-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <ConfirmImportModal
      :show="importDialog"
      @handleConfirm="confirmImportRecords()"
      @handleClose="importDialog = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  getProcessedPeopleById,
  getProcessedPeopleRecords,
  confirmImportRecords,
  rejectImportRecords,
} from '@/services/people'
import ConfirmImportModal from '../../Partials/ConfirmImportModal/ConfirmImportModal.vue'
import { PEOPLE_ACTIONS } from '@/helpers/upload-actions'

export default {
  name: 'ImportedPeoplePage',

  data() {
    return {
      loading: true,
      importedData: null,
      importDialog: false,
      pagination: {
        page: 1,
        limit: 20,
        offset: 0,
        total: 0,
      },
      filters: {},
      people: [],
    }
  },

  components: {
    ConfirmImportModal,
  },

  beforeMount() {
    this.$root.$on('change:filter', this.handleFilter)
  },

  mounted() {
    this.setImportedPageLayout(true)

    this.getProcessedPeopleById()
    this.getProcessedPeopleRecords()
  },

  beforeDestroy() {
    this.$root.$off('change:filter', this.handleFilter)
  },

  computed: {
    ...mapGetters(['importedPageLayout']),

    _headers() {
      const isAddUpdate = this.importedData?.action === PEOPLE_ACTIONS.AddUpdate
      const headers = isAddUpdate
        ? [
            {
              text: this.$t(
                'PeopleService.importedPeoplePageTable.headers.code'
              ),
              value: 'code',
              type: 'text',
            },
            {
              text: this.$t(
                'PeopleService.importedPeoplePageTable.headers.firstname'
              ),
              value: 'firstname',
              type: 'text',
            },
            {
              text: this.$t(
                'PeopleService.importedPeoplePageTable.headers.lastname'
              ),
              value: 'lastname',
              type: 'text',
            },
            {
              text: this.$t(
                'PeopleService.importedPeoplePageTable.headers.occupation'
              ),
              value: 'occupation',
              type: 'text',
            },
            {
              text: this.$t(
                'PeopleService.importedPeoplePageTable.headers.directManager'
              ),
              value: 'directManager',
              type: 'text',
            },
          ]
        : []

      headers.push(
        ...[
          {
            text: this.$t(
              'PeopleService.importedPeoplePageTable.headers.email'
            ),
            value: 'email',
            type: 'text',
          },
          {
            text: this.$t(
              'PeopleService.importedPeoplePageTable.headers.operation'
            ),
            value: 'operation',
            type: 'text',
          },
          {
            text: this.$t(
              'PeopleService.importedPeoplePageTable.headers.status'
            ),
            value: 'status',
            type: 'text-status',
          },
        ]
      )

      if (isAddUpdate) {
        headers.push({
          text: this.$t(
            'PeopleService.importedPeoplePageTable.headers.attributes'
          ),
          value: 'attributes',
          type: 'message-popup',
        })
      }

      return [
        ...headers,
        {
          text: this.$t(
            'PeopleService.importedPeoplePageTable.headers.message'
          ),
          value: 'message',
          type: 'text',
        },
        {
          text: this.$t(
            'PeopleService.importedPeoplePageTable.headers.importStatus'
          ),
          value: 'importStatus',
          type: 'text-status',
        },
        {
          text: this.$t(
            'PeopleService.importedPeoplePageTable.headers.importStatusMessage'
          ),
          value: 'importStatusMessage',
          type: 'text',
        },
      ]
    },
  },

  methods: {
    ...mapActions(['setImportedPageLayout']),

    async getProcessedPeopleById() {
      if (!this.$route.params.id) return

      await getProcessedPeopleById(this.$route.params.id).then(({ data }) => {
        this.importedData = data
      })
    },

    async getProcessedPeopleRecords() {
      if (!this.$route.params.id) return

      this.loading = true

      const payload = {
        processedFileId: this.$route.params.id,
        params: {
          limit: this.pagination.limit,
          offset: this.pagination.offset,
          ...this.filters,
        },
      }

      await getProcessedPeopleRecords(payload)
        .then(({ data, headers }) => {
          this.people = data.map(el => {
            return {
              ...el,
              operation: this.$t(
                `PeopleService.importedPeoplePageTable.operation.${el.operation}`
              ),
              status: {
                status: this.formatStatus(el.status),
                label: this.$t(
                  `PeopleService.importedPeoplePageTable.status.${el.status}`
                ),
              },
              attributes: {
                content: `${el.attributes ? el.attributes.length : 0} ${
                  el.attributes && el.attributes.length === 1
                    ? this.$t(
                        'PeopleService.importedPeoplePageTable.attributes.singular'
                      )
                    : this.$t(
                        'PeopleService.importedPeoplePageTable.attributes.plural'
                      )
                }`,
                type: 'list',
                label: this.$t(
                  'PeopleService.importedPeoplePageTable.headers.attributes'
                ),
                list:
                  !!el.attributes &&
                  Array.isArray(el.attributes) &&
                  el.attributes.length
                    ? el.attributes.map(el => ({
                        title: el.value,
                        subtitle: el.field,
                      }))
                    : [],
                width: '20rem',
              },
              message: el.message || '--',
              importStatus: {
                status: ['insert', 'update', 'delete'].includes(el.operation)
                  ? this.formatImpotedStatus(el.importStatus)
                  : '',
                label: ['insert', 'update', 'delete'].includes(el.operation)
                  ? this.$t(
                      `PeopleService.importedPeoplePageTable.importStatus.${el.importStatus}`
                    )
                  : '--',
              },
              directManager: el?.directManager?.email,
              occupation: el?.occupation?.title,
            }
          })

          let total = headers['x-count'] / this.pagination.limit
          if (total % 1 !== 0) total = total + 1

          this.pagination.total = Math.trunc(total)
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleConfirmImportRecords() {
      this.importDialog = true
    },

    async confirmImportRecords() {
      if (!this.$route.params.id) return

      this.loading = true

      await confirmImportRecords(this.$route.params.id)
        .then(() => {
          this.handleBack()
        })
        .catch(() => {
          const message = {
            type: 'error',
            message: this.$t('confirmImport.error'),
          }

          this.$emit('alert', message)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async rejectImportRecords() {
      if (!this.$route.params.id) return

      this.loading = true

      await rejectImportRecords(this.$route.params.id)
        .then(() => {
          this.handleBack()
        })
        .catch(() => {
          const message = {
            type: 'error',
            message: this.$t('rejectImport.error'),
          }

          this.$emit('alert', message)
        })
        .finally(() => {
          this.loading = false
        })
    },

    handlePagination(value) {
      this.pagination.page = value
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit

      this.getProcessedPeopleRecords()
    },

    handleStatus(event) {
      const payload = {
        status: event ? event.id : null,
      }

      this.filters = {
        ...this.filters,
        ...payload,
      }

      this.pagination.page = 1

      this.getProcessedPeopleRecords()
    },

    formatStatus(value) {
      const data = {
        alert: 'waiting',
        success: 'success',
        error: 'error',
      }

      return data[value]
    },

    formatImpotedStatus(value) {
      const data = {
        waiting: 'waiting',
        success: 'success',
        error: 'error',
        processing: 'waiting',
      }

      return data[value]
    },

    handleFilter(event) {
      this.filters = {
        ...this.filters,
        ...event,
      }

      this.pagination.page = 1

      this.getProcessedPeopleRecords()
    },

    handleBack() {
      this.setImportedPageLayout(false)
      this.$router.push({ name: 'PeoplePage' })
    },
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
