var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form"},[_c('div',{staticClass:"div-input",attrs:{"data-test-code":""}},[_c('CustomInput',{ref:"code",staticClass:"input-title",attrs:{"label":_vm.$t('LateralMenu.byCode')},on:{"handleInput":function($event){return _vm.handleChangeInput($event, 'code')}}})],1),_c('div',{staticClass:"div-input",attrs:{"data-test-firstName":""}},[_c('CustomInput',{ref:"firstName",staticClass:"input-title",attrs:{"label":_vm.$t('LateralMenu.byFirstname')},on:{"handleInput":function($event){return _vm.handleChangeInput($event, 'firstName')}}})],1),_c('div',{staticClass:"div-input",attrs:{"data-test-lastName":""}},[_c('CustomInput',{ref:"lastName",staticClass:"input-title",attrs:{"label":_vm.$t('LateralMenu.byLastname')},on:{"handleInput":function($event){return _vm.handleChangeInput($event, 'lastName')}}})],1),_c('div',{staticClass:"div-input",attrs:{"data-test-email":""}},[_c('CustomInput',{ref:"email",staticClass:"input-title",attrs:{"label":_vm.$t('LateralMenu.byEmail')},on:{"handleInput":function($event){return _vm.handleChangeInput($event, 'email')}}})],1),_c('div',{staticClass:"div-input",attrs:{"data-test-role":""}},[_c('ItemSelector',{ref:"role",attrs:{"menuConfig":{
        showTabs: false,
      },"inputConfig":{
        closeOnSelect: true,
        showAvatar: false,
        label: _vm.$t('LateralMenu.byRole'),
        placeholder: _vm.$t('LateralMenu.byRole'),
      },"menuOptions":_vm._roleOptions},on:{"update:item":function($event){return _vm.handleSelector($event, 'role')}}})],1),_c('div',{staticClass:"div-input",attrs:{"data-test-status":""}},[_c('ItemSelector',{ref:"status",attrs:{"menuConfig":{
        showTabs: false,
      },"inputConfig":{
        closeOnSelect: true,
        showAvatar: false,
        label: _vm.$t('LateralMenu.byStatus'),
        placeholder: _vm.$t('LateralMenu.byStatus'),
      },"menuOptions":_vm._statusOptions},on:{"update:item":function($event){return _vm.handleSelector($event, 'status')}}})],1),_c('div',{staticClass:"div-input",attrs:{"data-test-importStatus":""}},[_c('ItemSelector',{ref:"importStatus",attrs:{"menuConfig":{
        showTabs: false,
      },"inputConfig":{
        closeOnSelect: true,
        showAvatar: false,
        label: _vm.$t('LateralMenu.byImportStatus'),
        placeholder: _vm.$t('LateralMenu.byImportStatus'),
      },"menuOptions":_vm._importOptions},on:{"update:item":function($event){return _vm.handleSelector($event, 'importStatus')}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }