<template>
  <div id="data-page" data-test-id="data-page">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'DataPage',
}
</script>
