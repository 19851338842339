<template>
  <div class="form">
    <div class="div-input" data-test-name>
      <CustomInput
        ref="name"
        class="input-title"
        :label="$t('LateralMenu.byName')"
        @handleInput="handleChangeInput($event, 'name')"
      />
    </div>

    <div class="div-input" data-test-costCenter>
      <CustomInput
        ref="costCenter"
        class="input-title"
        :label="$t('LateralMenu.byCostCenter')"
        @handleInput="handleChangeInput($event, 'costCenter')"
      />
    </div>

    <div class="div-input" data-test-parentGroup>
      <CustomInput
        ref="parentGroup"
        class="input-title"
        :label="$t('LateralMenu.byParentGroup')"
        @handleInput="handleChangeInput($event, 'parentGroup')"
      />
    </div>

    <div class="div-input" data-test-name>
      <ItemSelector
        ref="operation"
        :menuConfig="{
          showTabs: false,
        }"
        :inputConfig="{
          closeOnSelect: true,
          showAvatar: false,
          label: $t('LateralMenu.byOperation'),
          placeholder: $t('LateralMenu.byOperation'),
        }"
        :menuOptions="_operationOptions"
        @update:item="handleSelector($event, 'operation')"
      />
    </div>

    <div class="div-input" data-test-status>
      <ItemSelector
        ref="status"
        :menuConfig="{
          showTabs: false,
        }"
        :inputConfig="{
          closeOnSelect: true,
          showAvatar: false,
          label: $t('LateralMenu.byStatus'),
          placeholder: $t('LateralMenu.byStatus'),
        }"
        :menuOptions="_statusOptions"
        @update:item="handleSelector($event, 'status')"
      />
    </div>

    <div class="div-input" data-test-importStatus>
      <ItemSelector
        ref="importStatus"
        :menuConfig="{
          showTabs: false,
        }"
        :inputConfig="{
          closeOnSelect: true,
          showAvatar: false,
          label: $t('LateralMenu.byImportStatus'),
          placeholder: $t('LateralMenu.byImportStatus'),
        }"
        :menuOptions="_importOptions"
        @update:item="handleSelector($event, 'importStatus')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportedPeople',

  data() {
    return {
      interval: null,
      allowEmit: true,
      form: {
        name: null,
        costCenter: null,
        parentGroup: null,
        operation: null,
        status: null,
        importStatus: null,
      },
    }
  },

  computed: {
    _operationOptions() {
      return [
        {
          value: 'operation',
          label: this.$t(
            'PeopleService.importedPeoplePageTable.headers.operation'
          ),
          type: 'listview',
          items: [
            {
              id: 'insert',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.operation.insert'
              ),
            },
            {
              id: 'update',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.operation.update'
              ),
            },
            {
              id: 'delete',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.operation.delete'
              ),
            },
            {
              id: 'null',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.operation.null'
              ),
            },
          ],
        },
      ]
    },

    _statusOptions() {
      return [
        {
          value: 'status',
          label: this.$t(
            'PeopleService.importedPeoplePageTable.headers.status'
          ),
          type: 'listview',
          items: [
            {
              id: 'alert',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.status.alert'
              ),
            },
            {
              id: 'success',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.status.success'
              ),
            },
            {
              id: 'error',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.status.error'
              ),
            },
          ],
        },
      ]
    },

    _importOptions() {
      return [
        {
          value: 'importStatus',
          label: this.$t(
            'PeopleService.importedPeoplePageTable.headers.importStatus'
          ),
          type: 'listview',
          items: [
            {
              id: 'waiting',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.importStatus.waiting'
              ),
            },
            {
              id: 'success',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.importStatus.success'
              ),
            },
            {
              id: 'error',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.importStatus.error'
              ),
            },
            {
              id: 'processing',
              label: this.$t(
                'PeopleService.importedPeoplePageTable.importStatus.processing'
              ),
            },
          ],
        },
      ]
    },
  },

  methods: {
    handleChangeInput(event, key) {
      this.form[key] = event || null
      if (this.allowEmit) this.emitFilter(this.form)
    },

    handleSelector(event, key) {
      this.form[key] = event ? event.id : null
      if (this.allowEmit) this.emitFilter(this.form)
    },

    async clearFilter() {
      this.allowEmit = false

      const event = {
        target: {
          value: '',
        },
      }

      await this.$refs.name.handleInput(event)
      await this.$refs.costCenter.handleInput(event)
      await this.$refs.parentGroup.handleInput(event)
      await this.$refs.operation.handleClear()
      await this.$refs.status.handleClear()
      await this.$refs.importStatus.handleClear()

      this.emitFilter({})

      this.allowEmit = true
    },

    emitFilter(payload) {
      console.log(payload)
      this.$root.$emit('change:filter', payload)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
