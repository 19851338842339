import { projectAuth } from '@/firebase/config'

export default function (error) {
  if ((error.response || {}).status === 401 && projectAuth.currentUser) {
    projectAuth.signOut().then(() => {
      window.location = '/login'
    })
  }

  return error
}
