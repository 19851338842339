const PEOPLE_ACTIONS = {
  AddUpdate: 'add-update',
  Delete: 'delete',
}

const GROUPS_ACTIONS = {
  FullHierarchyAddUpdate: 'full-hierarchy-add-update',
  PartialAddUpdate: 'partial-add-update',
  InformationUpdate: 'information-update',
}

const OCCUPATIONS_ACTIONS = {
  AddUpdate: 'add-update',
  Delete: 'delete',
}

export { PEOPLE_ACTIONS, GROUPS_ACTIONS, OCCUPATIONS_ACTIONS }
