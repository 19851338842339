import SyncPage from '@/views/SyncPage/SyncPage.vue'
import DataPage from '@/views/SyncPage/Tabs/DataPage/DataPage.vue'

import PeoplePage from '@/views/SyncPage/Tabs/DataPage/Tabs/PeoplePage/PeoplePage.vue'
import GroupsPage from '@/views/SyncPage/Tabs/DataPage/Tabs/GroupsPage/GroupsPage.vue'
import OccupationsPage from '@/views/SyncPage/Tabs/DataPage/Tabs/OccupationsPage/OccupationsPage.vue'

import ImportedPeoplePage from '@/views/SyncPage/Tabs/DataPage/Tabs/ImportedPeoplePage/ImportedPeoplePage.vue'
import ImportedGroupsPage from '@/views/SyncPage/Tabs/DataPage/Tabs/ImportedGroupPage/ImportedGroupsPage.vue'
import ImportedOccupationsPage from '@/views/SyncPage/Tabs/DataPage/Tabs/ImportedOccupationsPage/ImportedOccupationsPage.vue'

export const moduleRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/LoginPage.vue'),
  },
  {
    path: '/sync',
    name: 'SyncPage',
    component: SyncPage,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'data',
        name: 'DataPage',
        component: DataPage,
        redirect: { name: 'PeoplePage' },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'people',
            name: 'PeoplePage',
            component: PeoplePage,
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: 'imported/:id',
                name: 'ImportedPeoplePage',
                component: ImportedPeoplePage,
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
          {
            path: 'groups',
            name: 'GroupsPage',
            component: GroupsPage,
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: 'imported/:id',
                name: 'ImportedGroupsPage',
                component: ImportedGroupsPage,
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
          {
            path: 'occupations',
            name: 'OccupationsPage',
            component: OccupationsPage,
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: 'imported/:id',
                name: 'ImportedOccupationsPage',
                component: ImportedOccupationsPage,
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: '/sync/data/people',
  },
]
