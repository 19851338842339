import api from '@/services/Http'

export const getPeople = async data =>
  (await api()).get('/sync/people/import/verify-spreadsheet', { params: data })

export const getProcessedPeople = async data =>
  (await api()).get('/sync/people/processed-files', { params: data })

export const getProcessedPeopleById = async data =>
  (await api()).get(`/sync/people/processed-files/${data}`)

export const getProcessedPeopleRecords = async data =>
  (await api()).get(
    `/sync/people/processed-files/${data.processedFileId}/records`,
    { params: data.params }
  )

export const confirmImportRecords = async data =>
  (await api()).put(`/sync/people/processed-files/${data}/confirm-import`)

export const rejectImportRecords = async data =>
  (await api()).put(`/sync/people/processed-files/${data}/reject-import`)

export const verifySpreadSheet = async file => {
  const formData = new FormData()

  formData.append('file', file)

  return (await api()).post('/sync/people/import/verify-spreadsheet', formData)
}

export const sendTabsInformations = async tabsInformations => {
  return (await api()).post('/sync/people/import', tabsInformations)
}
