<template>
  <div data-app>
    <AlertBar ref="alertBar" />

    <UploadModal
      @updateOccupationsList="updateOccupationsList"
      @alert="handleAlert"
      ref="modalOccupationsUpload"
    />

    <v-card class="card" v-if="!importedPageLayout">
      <div class="header">
        <div class="header-title" data-test-header-title>
          <h1>{{ $t('OccupationsTab.title') }}</h1>
          <HowToBeginAndWatchAVideo videoType="listOccupations" short />
        </div>

        <div class="header-subtitle">
          <h3>{{ $t('OccupationsTab.subtitle') }}</h3>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="table-header">
        <span class="table-header-title">
          {{ $t('OccupationsTab.tableHeaderTitle') }}
        </span>

        <v-spacer />

        <ItemSelector
          ref="progressType"
          :menuConfig="{
            showTabs: false,
          }"
          :inputConfig="{
            closeOnSelect: true,
            showAvatar: false,
          }"
          :menuOptions="_statusOptions"
          @update:item="handleStatus($event)"
        >
          <template #default="{ focus, selectedItem }">
            <div
              class="filter-status"
              data-test-filter-input
              v-ripple
              @click="focus"
            >
              <div class="container">
                <div class="label">
                  {{
                    selectedItem && selectedItem.data.length
                      ? selectedItem.data[0].label
                      : $t('OccupationsTab.status.title')
                  }}
                </div>

                <v-icon class="droparrow" right size="0.625rem">
                  fi fi-br-caret-down
                </v-icon>
              </div>
            </div>
          </template>
        </ItemSelector>
      </div>

      <div v-if="loading">
        <v-skeleton-loader type="table-tbody" data-test-ranking-loading />
      </div>

      <div v-else class="table-holder">
        <DataTable
          id="DataTable"
          :title="$t('OccupationsTab.tableHeaderTitle')"
          :headers="_headers"
          :hideHeader="true"
          :hideOption="true"
          :hideSearch="true"
          :contentStyle="false"
          :enablePagination="false"
          :items="occupations"
          :itemsPerPage="pagination.limit"
          :skeletonLines="pagination.limit"
          @send="handleSend($event)"
          @search="handleSearch($event)"
        >
        </DataTable>

        <div class="pagination">
          <v-btn
            data-test-table-pagination-previous
            v-if="pagination.page > 1"
            x-small
            tile
            icon
            @click="handlePagination(pagination.page - 1)"
          >
            <v-icon size="10">fi fi-br-angle-left</v-icon>
          </v-btn>

          <div class="current">
            <span class="label-pagination"> {{ pagination.page }} </span>
          </div>

          <v-btn
            data-test-table-pagination-next
            v-if="pagination.page < pagination.total"
            x-small
            tile
            icon
            @click="handlePagination(pagination.page + 1)"
          >
            <v-icon size="10">fi fi-br-angle-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>

    <router-view @alert="handleAlert($event)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getProcessedOccupations } from '@/services/occupations'
import UploadModal from '@/views/SyncPage/Tabs/DataPage/Tabs/OccupationsPage/Partials/UploadModal/UploadModal.vue'

export default {
  name: 'OccupationsPage',

  components: {
    UploadModal,
  },

  data() {
    return {
      loading: true,
      pagination: {
        page: 1,
        limit: 20,
        offset: 0,
        total: 0,
      },
      filters: {},
      occupations: [],
    }
  },

  created() {
    this.setImportedPageLayout(!!this.$route.params.id)
    this.$root.$on('modalOccupationsUpload', this.handleUploadModal)
  },

  mounted() {
    this.setCurrentPage('OccupationsPage')
    this.setImportedPageLayout(!!this.$route.params.id)
    this.getProcessedOccupations()
  },

  watch: {
    importedPageLayout(val) {
      if (!val) this.filters = {}

      this.getProcessedOccupations()
    },
  },

  computed: {
    ...mapGetters(['importedPageLayout']),

    _statusOptions() {
      return [
        {
          value: 'status',
          label: 'Status',
          type: 'listview',
          items: [
            {
              id: 'validating',
              label: this.$t('OccupationsTab.status.validating'),
            },
            {
              id: 'waiting',
              label: this.$t('OccupationsTab.status.waiting'),
            },
            {
              id: 'rejected',
              label: this.$t('OccupationsTab.status.rejected'),
            },
            {
              id: 'importing',
              label: this.$t('OccupationsTab.status.importing'),
            },
            {
              id: 'imported',
              label: this.$t('OccupationsTab.status.imported'),
            },
            {
              id: 'error',
              label: this.$t('OccupationsTab.status.error'),
            },
          ],
        },
      ]
    },

    _headers() {
      return [
        {
          text: this.$t(
            'OccupationsService.importedFilesTable.headers.filename'
          ),
          value: 'filename',
          type: 'entity-item',
          sortable: true,
        },
        {
          text: this.$t('OccupationsService.importedFilesTable.headers.date'),
          value: 'date',
          type: 'text',
          sortable: true,
        },
        {
          text: this.$t(
            'OccupationsService.importedFilesTable.headers.occupationsAmount'
          ),
          value: 'occupationsAmount',
          type: 'text',
          sortable: true,
        },
        {
          text: this.$t(
            'OccupationsService.importedFilesTable.headers.amountSuccess'
          ),
          value: 'amountSuccess',
          type: 'text',
          sortable: true,
        },
        {
          text: this.$t(
            'OccupationsService.importedFilesTable.headers.amountError'
          ),
          value: 'amountError',
          type: 'text',
          sortable: true,
        },
        {
          text: this.$t(
            'OccupationsService.importedFilesTable.headers.amountAlert'
          ),
          value: 'amountAlert',
          type: 'text',
          sortable: true,
        },
        {
          text: this.$t('OccupationsService.importedFilesTable.headers.status'),
          value: 'status',
          type: 'text-status',
          sortable: true,
        },
        {
          text: this.$t(
            'OccupationsService.importedFilesTable.headers.actions'
          ),
          value: 'actions',
          type: 'action-buttons',
          width: '100',
          sortable: false,
        },
      ]
    },
  },

  beforeDestroy() {
    this.$root.$off('modalOccupationsUpload', this.handleUploadModal)
  },

  methods: {
    ...mapActions(['setImportedPageLayout', 'setCurrentPage']),

    async getProcessedOccupations() {
      this.loading = true

      const payload = {
        limit: this.pagination.limit,
        offset: this.pagination.offset,
        ...this.filters,
      }

      await getProcessedOccupations(payload)
        .then(({ data, headers }) => {
          this.occupations = data.map(el => {
            return {
              ...el,
              filename: { title: el.filename },
              date: this.formatDate(el.date),
              status: {
                label: this.$t(`OccupationsTab.status.${el.status}`),
                status: this.formatStatus(el.status),
                tooltip: el.importStatusMessage || '',
              },
              actions: ['send'],
            }
          })

          let total = headers['x-count'] / this.pagination.limit
          if (total % 1 !== 0) total = total + 1

          this.pagination.total = Math.trunc(total)
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateOccupationsList() {
      this.getProcessedOccupations()
    },

    handlePagination(value) {
      this.pagination.page = value
      this.pagination.offset =
        (this.pagination.page - 1) * this.pagination.limit

      this.getProcessedOccupations()
    },

    handleAlert(event) {
      const config = {
        messagePrefix: '',
        description: event.message,
        type: event.type,
        hasLeftBorder: false,
        hasFooter: false,
      }

      this.$refs.alertBar.displayAlert(config)
    },

    handleUploadModal() {
      this.$nextTick(() => {
        this.$refs.modalOccupationsUpload?.handleDialog()
      })
    },

    handleSend(event) {
      this.$router.push({
        name: 'ImportedOccupationsPage',
        params: { id: event.id },
      })
    },

    handleSort({ sortBy: sortField, sortDesc }) {
      const payload = {
        sortBy: sortField.length ? sortField[0] : null,
        sortOrder: sortField.length ? (sortDesc[0] ? 'desc' : 'asc') : null,
      }

      this.filters = {
        ...this.filters,
        ...payload,
      }

      this.getProcessedOccupations()
    },

    handleSearch(search) {
      const payload = {
        filename: search.name !== '' ? search.name : null,
      }

      this.filters = {
        ...this.filters,
        ...payload,
      }

      this.getProcessedOccupations()
    },

    handleStatus(event) {
      const payload = {
        status: event ? event.id : null,
      }

      this.filters = {
        ...this.filters,
        ...payload,
      }

      this.pagination.page = 1

      this.getProcessedOccupations()
    },

    formatStatus(value) {
      const data = {
        validating: 'waiting',
        waiting: 'waiting',
        rejected: 'error',
        importing: 'waiting',
        imported: 'success',
        error: 'error',
      }

      return data[value]
    },

    formatDate(value) {
      let newDate = new Date(value)

      return `${newDate.toLocaleDateString()} ${newDate.toLocaleTimeString()}`
    },
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
,
