<template>
  <div class="add-file-input">
    <v-row>
      <div class="upload-icon-holder">
        <v-icon class="upload-icon">fi-rr-cloud-upload</v-icon>
      </div>
      <div class="cutom-file-holder">
        <label class="custom-file-upload">
          <input
            ref="inputFile"
            type="file"
            @change="seeFiles"
            accept=".csv,.xlsx"
          />
          <span :class="{ 'file-name': label }">
            {{ label ? label : $t('PeopleService.Modal.addFileImport') }}
          </span>
        </label>
        <small>
          {{ $t('PeopleService.Modal.fileInstruction') }}
        </small>
      </div>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
    },
  },
  methods: {
    seeFiles(event) {
      this.$emit('file', event.target.files[0])
      this.$refs.inputFile.value = null
    },
  },
}
</script>
<style src="./style.scss" lang="scss" scoped></style>
