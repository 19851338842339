<template>
  <Modal
    ref="modalPeopleUpload"
    :title="$t('PeopleService.Modal.title')"
    width="60%"
    :zIndex="999"
    :has-tab="false"
  >
    <template v-slot:[`no-tab`]>
      <div class="modal-upload">
        <div class="modal-upload-info" v-show="!showForm && !loading">
          <p>
            {{ $t('PeopleService.Modal.headerInstruction') }}
          </p>
          <a href="/modelo.xlsx" download>{{
            $t('PeopleService.Modal.downloadSheets')
          }}</a>
        </div>

        <AddFile @file="validateFile" :label="fileName" />

        <div v-if="showForm">
          <span class="modal-upload-subtitle">
            {{ $t('PeopleService.Modal.subtitle') }}
          </span>
          <v-divider></v-divider>
        </div>

        <div v-if="loading" class="loading">
          <v-progress-circular indeterminate color="primary" />
        </div>

        <v-container fluid v-if="showForm">
          <v-radio-group v-model="action" inline class="modal-upload-action">
            <v-radio
              :label="$t('PeopleService.Modal.labels.action.addUpdate')"
              :value="PEOPLE_ACTIONS.AddUpdate"
            ></v-radio>
            <v-radio
              :label="$t('PeopleService.Modal.labels.action.delete')"
              :value="PEOPLE_ACTIONS.Delete"
            ></v-radio>
          </v-radio-group>
        </v-container>

        <v-form
          ref="form"
          @submit.prevent
          v-show="showForm"
          class="modal-upload-form"
          v-model="valid"
        >
          <v-container>
            <div class="single-input">
              <SingleSelection
                :selectedItem="selectedTab"
                :label="$t('PeopleService.Modal.labels.peopleLabel')"
                :placeholder="$t('PeopleService.Modal.labels.peopleLabel')"
                :items="tabs"
                @selectOption="handleTabSelection"
              />
            </div>
            <div
              v-if="selectedTab && action === PEOPLE_ACTIONS.AddUpdate"
              class="inline-inputs"
            >
              <div class="input-holder">
                <SingleSelection
                  :label="$t('PeopleService.Modal.labels.peopleCodeLabel')"
                  :placeholder="
                    $t('PeopleService.Modal.labels.peopleCodeLabel')
                  "
                  :items="peopleColumns"
                  @selectOption="handleSelectOption($event, 'code')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('PeopleService.Modal.labels.columnLabelFirstName')"
                  :placeholder="
                    $t('PeopleService.Modal.labels.columnLabelFirstName')
                  "
                  :items="peopleColumns"
                  :rules="[rules.required]"
                  @selectOption="handleSelectOption($event, 'firstname')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('PeopleService.Modal.labels.columnLabelLastName')"
                  :placeholder="
                    $t('PeopleService.Modal.labels.columnLabelLastName')
                  "
                  :items="peopleColumns"
                  :rules="[rules.required]"
                  @selectOption="handleSelectOption($event, 'lastname')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('PeopleService.Modal.labels.emailLabel')"
                  :placeholder="$t('PeopleService.Modal.labels.emailLabel')"
                  :items="peopleColumns"
                  :rules="[rules.required]"
                  @selectOption="handleSelectOption($event, 'email')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('PeopleService.Modal.labels.attributesLabel')"
                  :placeholder="
                    $t('PeopleService.Modal.labels.attributesLabel')
                  "
                  :items="peopleColumns"
                  @selectOption="handleSelectOption($event, 'attributes')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('PeopleService.Modal.labels.roleLabel')"
                  :placeholder="$t('PeopleService.Modal.labels.roleLabel')"
                  :items="peopleColumns"
                  :rules="[rules.required]"
                  @selectOption="handleSelectOption($event, 'role')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('PeopleService.Modal.labels.birthDate')"
                  :placeholder="$t('PeopleService.Modal.labels.birthDate')"
                  :items="peopleColumns"
                  @selectOption="handleSelectOption($event, 'birthDate')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('PeopleService.Modal.labels.directManager')"
                  :placeholder="$t('PeopleService.Modal.labels.directManager')"
                  :items="peopleColumns"
                  @selectOption="handleSelectOption($event, 'directManager')"
                />
              </div>
              <div class="input-holder">
                <SingleSelection
                  :label="$t('PeopleService.Modal.labels.occupation')"
                  :placeholder="$t('PeopleService.Modal.labels.occupation')"
                  :items="peopleColumns"
                  @selectOption="handleSelectOption($event, 'occupation')"
                />
              </div>
            </div>
            <div
              v-if="selectedTab && action === PEOPLE_ACTIONS.Delete"
              class="inline-inputs"
            >
              <div class="input-holder">
                <SingleSelection
                  :label="$t('PeopleService.Modal.labels.emailLabel')"
                  :placeholder="$t('PeopleService.Modal.labels.emailLabel')"
                  :items="peopleColumns"
                  :rules="[rules.required]"
                  @selectOption="handleSelectOption($event, 'email')"
                />
              </div>
            </div>
          </v-container>
        </v-form>

        <v-divider v-show="showForm && selectedTab"></v-divider>

        <div class="dialog-footer" v-show="showForm && selectedTab">
          <v-btn
            @click="handleDialog()"
            class="dialog-footer-btnCancel"
            depressed
            plain
          >
            {{ $t('PeopleService.modalButtons.cancel') }}
          </v-btn>

          <v-btn
            @click="SendTabsInformations"
            class="dialog-footer-btn"
            depressed
          >
            {{ _buttonLabel }}
          </v-btn>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import { PEOPLE_ACTIONS } from '@/helpers/upload-actions'
import AddFile from '@/views/SyncPage/Tabs/DataPage/Tabs/PeoplePage/Partials/UploadModal/Partials/AddFile/AddFile.vue'
import {
  verifySpreadSheet,
  sendTabsInformations,
} from '@/services/people/index'

export default {
  components: {
    AddFile,
  },
  data() {
    return {
      PEOPLE_ACTIONS,
      action: PEOPLE_ACTIONS.AddUpdate,
      showForm: false,
      fileName: '',
      loading: false,
      columns: {},
      tabsInformations: {
        fileLocationId: '',
        tab: {
          name: '',
          columns: [],
        },
      },
      selectedTab: '',
      valid: false,
      rules: {
        required: v => {
          const requiredLabel = this.$t('PeopleService.Modal.requiredField')

          if (v instanceof Array && v.length == 0) return requiredLabel
          return !!v || requiredLabel
        },
      },
    }
  },
  watch: {
    selectedTab() {
      this.reset()
    },
  },
  computed: {
    tabs() {
      return this.columns.tabs?.map(el => el.name)
    },
    peopleColumns() {
      return this.columns?.tabs?.find(el => el.name === this.selectedTab)
        .columns
    },
    _buttonLabel() {
      return this.action === PEOPLE_ACTIONS.AddUpdate
        ? this.$t('PeopleService.modalButtons.loadPeople')
        : this.$t('PeopleService.modalButtons.deletePeople')
    },
  },
  methods: {
    initiliazeData() {
      this.action = PEOPLE_ACTIONS.AddUpdate
      this.showForm = false
      this.fileName = ''
      this.columns = {}
      this.selectedTab = ''
      this.tabsInformations = {
        fileLocationId: '',
        tab: {
          name: '',
          columns: [],
        },
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    handleDialog() {
      this.initiliazeData()
      this.$refs.modalPeopleUpload.handleDialog()
    },
    handleTabSelection({ value }) {
      this.selectedTab = value
      this.tabsInformations.tab.name = value
    },
    handleSelectOption({ value }, name) {
      const columnWithAtributte = {
        column: value,
        attribute: name,
      }

      this.tabsInformations.tab.columns.push(columnWithAtributte)
    },
    async validateFile(file) {
      this.loading = true
      if (file) {
        this.fileName = file.name
        await verifySpreadSheet(file)
          .then(response => {
            this.loading = false
            this.showForm = true
            this.columns = response.data
          })
          .catch(error => {
            this.loading = false
            this.fileName = ''
            this.showForm = false
            this.$emit('alert', {
              type: 'error',
              message: error?.response?.data?.message || this.$t('uploadError'),
            })
          })
      }
    },
    async SendTabsInformations() {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }

      this.tabsInformations.action = this.action
      this.tabsInformations.fileLocationId = this.columns.fileLocationId

      this.loading = true
      this.showForm = false

      await sendTabsInformations(this.tabsInformations)
        .then(response => {
          this.$emit('alert', {
            type: 'success',
            message: response.data.message,
          })
          this.loading = false
          this.$emit('updatePeopleList')
          this.handleDialog()
        })
        .catch(error => {
          this.loading = false
          this.showForm = true
          this.$emit('alert', {
            type: 'error',
            message: error.response.data.message,
          })
        })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
